import React, { Component } from "react";

class Terms extends Component {
  render() {
    return (
      <div className="container contenido about">
        <div className="border-p">
          <div className="row">
            <div className="WordSection1">
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "center",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    TERMS AND CONDITIONS OF USE
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Last Modified: January 14, 2021
                </span>
              </p>
              <p className="MsoNormal">
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    &nbsp;
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Innapex Incorporated operating as MyPick.Is (“MyPick.Is”,
                  "we", "us" or "our") is a corporation formed pursuant to the
                  Canada Business Corporations Act (R.S.C., 1985, c. C-44). In
                  consideration for permitting your access to our website and
                  online platform and other good and valuable consideration, you
                  agree as follows:
                </span>

                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    &nbsp;
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  These terms and conditions (the "Terms") form a legally
                  binding agreement which govern your access to and use of our
                  website, our online platform hosted at{" "}
                  <a href="http://www.mypick.is">
                    <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                      www.mypick.is,
                    </span>
                  </a>{" "}
                  and if later made available our iOS and Android mobile
                  applications. Collectively our website, applications and web
                  platform are referred to in these Terms as the “MyPick.Is
                  Platform”.
                </span>

                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    &nbsp;
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    &nbsp;
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    THESE TERMS HAVE PROVISIONS WHICH LIMIT OUR LIABILITY AND
                    IMPOSE OBLIGATIONS ON YOU.
                  </span>
                </b>
                <p className="MsoNormal">
                  <b>
                    <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                      You must review these Terms carefully before using the
                      MyPick.Is Platform. You, the user ("you" or "your"),
                      represent and warrant that (i) you are at least 18 years
                      old; and (ii) you have read and understand these Terms and
                      agree to be bound by them.
                    </span>
                  </b>
                </p>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    IF YOU ARE USING THE MYPICK.IS PLATFORM ON BEHALF OF, OR IN
                    THE EMPLOY OF, AN ORGANIZATION (CORPORATION, TRUST,
                    PARTNERSHIP, ETC.), YOU ARE AGREEING TO THESE TERMS FOR THAT
                    ORGANIZATION AND REPRESENTING AND WARRANTING THAT YOU HAVE
                    THE AUTHORITY TO BIND THAT ORGANIZATION TO THESE TERMS.
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  . In such a case, "you" and "your" will also refer to that
                  organization and yourself individually. For greater clarity,
                  both you as an individual and your organization are legally
                  bound by these Terms which form an agreement between you and
                  Innapex Incorporated
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    AMENDMENTS
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  As the MyPick.Is Platform continues to evolve, we may, at any
                  time, revise these Terms and our policies by updating this
                  page or the page hosting the relevant policy. The date of the
                  last version of these Terms is posted above. As you are bound
                  by these Terms each time you use the MyPick.Is Platform, you
                  are responsible for periodically reviewing the amendments to
                  these Terms and you are deemed to have accepted and agreed to
                  such amendments by accessing and using the MyPick.Is Platform
                  after such amendments have been posted. If you do not agree
                  with the amendments, you shall immediately stop accessing the
                  MyPick.Is Platform and terminate your account, subject to the
                  terms provided for herein. We may also undertake to send you
                  an email or display notice of any changes to the Terms or
                  policies in your account.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    PRIVACY
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  We use personal information you provide us in accordance with
                  our privacy policy, which is incorporated by reference and
                  available online at{" "}
                  <a href="http://www.mypick.is/privacy">
                    <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                      www.mypick.is/privacy.
                    </span>
                  </a>{" "}
                  By using the MyPick.Is Platform, you consent to such
                  processing and you represent to us that all information
                  provided by you is accurate
                  <br></br>
                  If you use the MyPick.Is Platform to carry on your own
                  business, you agree that it is your responsibility to ensure
                  you comply with applicable privacy laws on the collection,
                  storage and use of personal information you collect on or as a
                  result of your use of the MyPick.Is Platform. You agree and
                  acknowledge this likely requires you have your own privacy
                  policy. It also requires that you collect consent from your
                  own customers to send them commercial electronic messages such
                  as emails and text messages (CEMs). As the MyPick.Is Platform
                  facilitates your sending of CEMs, you represent and warrant
                  that you will only cause such messages to be sent using our
                  platform in accordance with all applicable laws, rules and
                  regulations.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Use of Aggregate Data.
                  </span>
                </b>
                <p
                  className="MsoNormal"
                  style={{ fontFamily: "Helvetica, sans-serif" }}
                >
                  You agree that we shall own, and shall be entitled to use,
                  sell and license, and shall have a perpetual right to use,
                  sell and license, any data gathered or compiled on an
                  aggregate basis on or via the MyPick.Is Platform. Data
                  gathered or compiled on an aggregate basis includes where the
                  data is in a format that does not refer to any personal
                  information or information which might reasonably be used to
                  identify you or your organization’s customers.
                </p>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    About Our Online Service
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  The MyPick.Is Platform allows you and other end-users to log
                  and track orders and facilitate curbside pickup and delivery.
                  The MyPick.Is Platform also offers management and
                  communication tools that can be used by you and other
                  end-users to notify customers of their order status.
                  <br></br>
                  <br></br>
                  Your relationship with us is that of an independent
                  contracting party. You are not as an employee of ours and
                  regardless of whether you are acting individually or if you
                  are operating your own business in using the MyPick.Is
                  Platform, you are not an agent, joint venturer or partner of
                  ours and you hereby waive the application of the Partnerships
                  Act, RSO 1990, c P.5 in Ontario, Canada (where we are based)
                  and any successor or similar legislation in any other
                  jurisdiction. You agree that to the fullest extent permitted
                  by law, no partnership relationship shall exist or be deemed
                  to exist between you and us.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Establishing an Account
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  To use the MyPick.Is Platform you will be required to register
                  an account and provide certain personal information as
                  referenced in our privacy policy. Licensed account access and
                  the creation of accounts are subject to the terms of a
                  platform agreement (“Platform Agreement”), which your
                  organization is required to enter in order to grant you and
                  other end-users account access.
                  <br></br>
                  <br></br>
                  Regardless of whether you pay for your account directly,
                  indirectly or you operate a free account, you agree that
                  access to your account constitutes good and valuable
                  consideration in exchange for agreeing to these Terms our
                  privacy policy and all other documents and policies
                  incorporated by reference.
                  <br></br>
                  <br></br>
                  Upon establishing an account, we grant you a non-transferable,
                  non-exclusive license to access the MyPick.Is Platform in
                  accordance with these Terms and the Platform Agreement.
                  However, we reserve the right to revoke that license and your
                  account access to the MyPick.Is Platform without justification
                  or cause, at any time. We make no representations or
                  warranties as to the ongoing availability of the MyPick.Is
                  Platform, or your access to it.
                  <br></br>
                  <br></br>
                  Your relationship with us is that of an independent
                  contracting party. You are not as an employee of ours and
                  regardless of whether you are acting individually or if you
                  are operating your own business in using the MyPick.Is
                  Platform, you are not an agent, joint venturer or partner of
                  ours and you hereby waive the application of the Partnerships
                  Act, RSO 1990, c P.5 in Ontario, Canada (where we are based)
                  and any successor or similar legislation in any other
                  jurisdiction. You agree that to the fullest extent permitted
                  by law, no partnership relationship shall exist or be deemed
                  to exist between you and us.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Account Not Transferrable
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Access to your account is not transferrable and is only
                  intended for you, the individual who established the account,
                  even if your account is paid for or made accessible to you by
                  an organization (such as your employer or other third party)
                  or registered in the name of an organization with which you
                  are affiliated (for example as an owner, director etc.). As a
                  result, you are not permitted to change the name associated
                  with your account, if specified.
                  <br></br>
                  <br></br>
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Account Security
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Upon setting up an account, you will be required to create a
                  username and password. You are responsible for safeguarding
                  the password you use to access the MyPick.Is Platform and you
                  agree not to disclose your password to any third-party.
                  <br></br>
                  <br></br>
                  You agree to use a unique password for your account which you
                  do not use for any other online service. As we may send
                  password reset notices and links to your email account
                  registered on the MyPick.Is Platform (i) you are responsible
                  for ensuring that your email address provided to us is
                  accurate; and (ii) you represent and warrant to us, and agree
                  that you will ensure, you are the sole person, at all times,
                  with access to the email account registered in connection with
                  your account.
                  <br></br>
                  <br></br>
                  You agree you are responsible for any activity on your account
                  and all correspondence provided to us from any email address
                  or phone number used to register your account, whether or not
                  you authorized that activity or correspondence. You agree that
                  we are, in respect of any instructions or actions taken by a
                  person using your account, entitled to assume that the person
                  is you; the person and/or entity whose name and personal
                  information is registered and associated with the account.
                  <br></br>
                  <br></br>
                  You must immediately notify us of any unauthorized use of your
                  account.<br></br>
                  <br></br>
                  You must inform us of any changes to your contact details and
                  other information provided to us, including, but not limited
                  to, your email address, address and telephone number.<br></br>
                  <br></br>
                  While we and our third party software and technology providers
                  take certain security measures in relation to the MyPick.Is
                  Platform, you acknowledge that the technical processing and
                  transmission of the MyPick.Is Platform and related data and
                  information, including your account data and information will
                  involve transmissions over various networks and devices,
                  including networks and devices not owned or controlled by us.
                  We rely on a number of third parties to make the MyPick.Is
                  Platform available, including data and web hosting providers.
                  You accept all such risks in using the MyPick.Is Platform and
                  you agree and acknowledge that in using online platforms,
                  there is always a risk of unauthorized access to and use of
                  your information, including your business and personal
                  information.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Acceptable Use of the MyPick.Is Platform
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  1. Will not use the MyPick.Is Platform in a way that has any
                  unlawful or fraudulent purpose or effect;<br></br>
                  <br></br>
                  2. Will comply with all applicable laws, rules and
                  regulations;
                  <br></br>
                  <br></br>
                  3. Will not use or disclose personally identifiable
                  information belonging to others except (i) with their consent;
                  and (ii) in accordance with applicable privacy laws, rules and
                  regulations;<br></br>
                  <br></br>
                  4. Will not upload, copy, distribute, share or otherwise use
                  or generate data or content that is unlawful, obscene,
                  defamatory, libelous, harmful, hateful, harassing, bullying,
                  sexual in nature, threatening, racially or ethnically
                  offensive or abusive, that would violate a third party’s
                  rights or constitute or encourage a criminal offense;<br></br>
                  <br></br>
                  5. Will not upload, transmit, disseminate, post, share, store,
                  use any content, data or information, perform any services or
                  do anything that infringes on, or contributes to any
                  infringement of, any intellectual property rights; including
                  copyright, trademark, patent or trade secret rights, whether
                  of ours or any third party;<br></br>
                  <br></br>
                  6. Will not disclose your password or transfer your account to
                  any third party, or allow any third party to access your
                  account;<br></br>
                  <br></br>
                  7. Will not impersonate any person or entity;<br></br>
                  <br></br>
                  8. Will not use any software bot or data scraping techniques
                  that accesses the MyPick.Is Platform to scrape or pull data
                  for any purpose, whether such data was displayed publicly or
                  not.<br></br>
                  <br></br>
                  9. Will not translate, reverse engineer, decompile,
                  disassemble, modify or create derivative works based on the
                  MyPick.Is Platform and its underlying software code; and
                  <br></br>
                  <br></br>
                  10. Will not circumvent, disable, violate or otherwise
                  interfere with any security related feature of the MyPick.Is
                  Platform.
                  <br></br>
                  <br></br>
                  We may, but have no obligation to, remove users and suspend or
                  block user accounts from the MyPick.Is Platform that we
                  determine, in our sole discretion, to have, or which may
                  reasonably appear to have, violated these Terms.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Fees
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  For organizations to access the MyPick.Is Platform and create
                  end-user accounts for their staff, managers and agents, they
                  will be required to enter a platform agreement (“Platform
                  Agreement”). Each Platform Agreement sets out the fees we
                  charge for accessing and using the MyPick.Is Platform via
                  end-user accounts.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Taxes
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  You agree and acknowledge that if you are operating a
                  business, offering products or providing services via the
                  MyPick.Is Platform, you are required to collect and remit
                  sales, income and other taxes to applicable government
                  authorities in connection with the provisions of your goods
                  and services. You agree that we are not responsible for, and
                  shall not be liable in connection with, your failure to
                  collect and remit any and all such taxes. To the extent you
                  earn any income as a result of using the MyPick.Is Platform,
                  you agree that it shall be your sole responsibility for
                  declaring and paying all taxes associated therewith, including
                  but not limited to sales and income tax, in all applicable
                  jurisdictions. You agree that upon request and subject to
                  applicable laws, we may share (and may be required to share)
                  records related to your income and sales with taxing
                  authorities.
                  <br></br>
                  <br></br>
                  You agree to indemnify us against any claims from a government
                  or taxing authority arising from or related to your failure to
                  pay applicable sales, income and any other taxes.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    ACCEPTANCE OF RISK AND DISCLAIMERS
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Our MyPick.Is Platform is provided "as is" without warranty
                    of any kind, including but not limited to, all implied
                    warranties and conditions of merchantability and fitness for
                    a particular purpose. We hereby disclaim all warranties and
                    conditions of any kind, whether express, implied or
                    statutory.
                    <br></br>
                    <br></br>
                    You accept all risks associated with using the MyPick.Is
                    Platform.
                    <br></br>
                    <br></br>
                    Without limiting any other section of these Terms, you agree
                    that we shall not be responsible for any damages you suffer
                    arising from the acts or omissions, including the negligent
                    acts or omissions, of other users on the MyPick.Is Platform,
                    our independent contractors, payment processors or
                    third-party service providers, including for example, our
                    providers that facilitate the sending and receipt of SMS
                    messages.
                    <br></br>
                    <br></br>
                    You agree that, while we strive to have the MyPick.Is
                    Platform error free and uninterrupted, we do not guarantee
                    the absence of errors or interruptions. You agree that we
                    shall not be held liable for any damage such errors or
                    interruptions may cause. We make no representations and
                    grant no warranties as to the uptime or ongoing availability
                    of the MyPick.Is Platform. We may also perform scheduled
                    maintenance which will result in the MyPick.Is Platform
                    being unavailable for certain periods of time.
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Limitation of Our liability
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    YOU AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY LAW, IN
                    NO EVENT WILL WE, OUR OFFICERS, DIRECTORS, SHAREHOLDERS,
                    CONTRACTORS OR EMPLOYEES, BE LIABLE TO YOU FOR ANY DIRECT,
                    INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
                    CONSEQUENTIAL DAMAGES, HOWSOEVER CAUSED, INCLUDING BY
                    NEGLIGENCE OR OTHERWISE, REGARDLESS OF LEGAL THEORY AND
                    WHETHER OR NOT WE HAVE BEEN WARNED OF THE POSSIBILITY OF
                    SUCH DAMAGES AND WHETHER THOSE DAMAGES WERE FORESEEABLE OR
                    NOT.
                    <br></br>
                    <br></br>
                    IF YOU ARE DISSATISFIED WITH THE MYPICK.IS PLATFORM, OR DO
                    NOT AGREE WITH ANY PART OF THESE TERMS, OR HAVE ANY OTHER
                    DISPUTE OR CLAIM WITH OR AGAINST US, OUR OFFICERS,
                    DIRECTORS, SHAREHOLDERS OR EMPLOYEES, THEN YOUR SOLE AND
                    EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE
                    MYPICK.IS PLATFORM.
                    <br></br>
                    <br></br>
                    IN ADDITION TO YOUR AGREEMENT TO NOT HOLD THE ABOVE ENTITIES
                    AND PERSONS LIABLE FOR ANY DAMAGES, IN THE EVENT A COURT OR
                    ARBITRATOR OF COMPETENT JURISDICTION DECLINES TO UPHOLD SAID
                    CLAUSE, YOU AGREE THAT IN NO CIRCUMSTANCES SHALL THE
                    AGGREGATE LIABILITY FOR ANY AND ALL CLAIMS RELATING TO OR IN
                    ANY WAY ARISING FROM THE USE OF THE MYPICK.IS PLATFORM, OR
                    IN ANY WAY RELATED TO THESE TERMS, BE MORE THAN THE GREATER
                    OF (I) CAD $50.00 OR (II) THE AMOUNT BILLED TO AND PAID BY
                    YOU OR YOUR ORGANIZATION TO ACCESS AND USE YOUR ACCOUNT FOR
                    THE LAST THREE (3) MONTHS.
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    YOU AGREE AND ACKNOWLEDGE THAT WE WOULD NOT ENTER INTO THIS
                    AGREEMENT OR GRANT ACCESS TO THE MYPICK.IS PLATFORM WITHOUT
                    THESE RESTRICTIONS AND LIMITATIONS ON OUR LIABILITY.
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Indemnification
                  </span>
                </b>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    You agree to indemnify us, our employees, contractors,
                    shareholders, directors and officers, and to defend and hold
                    each of them harmless, from any and all claims and
                    liabilities (including reasonable legal fees) which may
                    arise from (i) your violation of these Terms or any policy
                    incorporated by reference; (ii) your violation of any
                    third-party right; (iii) any breach of a representation or
                    warranty made by you to us, either in these Terms, privacy
                    policy or otherwise; (iv) any claim for damages suffered by
                    another user of our service, or any end customer you sell to
                    or who consumes your products or services, which you caused
                    or contributed to; and (v) any claim or penalties imposed on
                    us arising from or related to your failure to collect or pay
                    any applicable taxes on amounts you earn from using the
                    MyPick.Is Platform.
                  </span>
                </b>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Proprietary Rights
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  The MyPick.Is Platform contains open source and public domain
                  content, licenced content as well as proprietary content owned
                  by us and by third parties. You are not permitted to copy, use
                  or distribute any content (including but not limited to text,
                  software code, images, trademarks, videos and audio) on the
                  MyPick.Is Platform without the express consent of the owner.
                  <br></br>
                  <br></br>
                  All rights, title and interest in and to the MyPick.Is
                  Platform are and will remain the exclusive property of Innapex
                  Incorporated and our licensors.
                  <br></br>
                  <br></br>
                  The MyPick.Is Platform and all content thereon are protected
                  by copyright, trademark and other laws of Canada, the United
                  States and foreign countries. You agree not to reproduce,
                  modify or prepare derivative works, distribute, sell,
                  transfer, publicly display, publicly perform, transmit, or
                  otherwise use the MyPick.Is Platform or any content thereon,
                  without our express written consent.
                  <br></br>
                  <br></br>
                  You are not permitted to use any trademark or trade name of
                  Innapex Incorporated, including our logo, without our express
                  permission.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Your Content
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  The MyPick.Is Platform permits you and other users the ability
                  to upload and post content ("User Content"). Subject to our
                  rights as to aggregate data ownership and use, to the extent
                  you create User Content and are the owner thereof, we claim no
                  ownership interest in your User Content. However, you agree
                  that we may, in our sole and absolute discretion, remove your
                  content, with or without reason or justification.
                  <br></br>
                  <br></br>
                  In connection with your User Content, you represent and
                  warrant that you own or have the necessary licenses, rights,
                  consents and/or permissions to use and authorize us to use
                  your User Content in the manner contemplated by the MyPick.Is
                  Platform and these Terms. You hereby grant us a non-exclusive,
                  transferable, sub-licensable, royalty-free, worldwide license
                  to use any of your User Content that you post or upload to the
                  MyPick.Is Platform in order to facilitate the ordinary use of
                  the MyPick.Is Platform.
                  <br></br>
                  <br></br>
                  We do not consider proposals or ideas, including without
                  limitation ideas for new products, features, technologies,
                  promotions, product names, feedback and suggested improvements
                  you provide us (“Feedback”) to be confidential information. If
                  you send any Feedback to us, you acknowledge and agree that we
                  shall not be under any obligation of confidentiality with
                  respect to the Feedback and nothing in these Terms limits or
                  restricts our right to independently use, develop, evaluate,
                  or market products or services, whether incorporating the
                  Feedback or otherwise.
                  <br></br>
                  <br></br>
                  Where you access your account as a result of an employer or
                  other third party who pays for your account access, you shall
                  have no reasonable expectation as to the privacy of your
                  account as between you and said employer or third party and
                  the electronic messages and information within your account.
                  Accordingly, you should not use your account for any personal
                  communications.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Copyright Notice
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  If you believe that your copyrighted work has been copied in a
                  way that constitutes copyright infringement and is accessible
                  on the MyPick.Is Platform, please notify us at{" "}
                  <a href="support@mypick.is">
                    <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                      support@mypick.is.
                    </span>
                  </a>
                  While we take no responsibility for any user who breaches your
                  copyright or other intellectual property rights, we may, in
                  our sole discretion and without liability, undertake to
                  attempt to contact the infringer on your behalf and/or cancel
                  the infringer's account.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Linked Sites
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Whether or not we are affiliated with websites or third-party
                  vendors that may be linked to the MyPick.Is Platform, you
                  agree that we are not responsible for their content. Internet
                  links found on the MyPick.Is Platform, whether posted by us,
                  another user or other third party, are not an endorsement and
                  we do not represent or warrant the accuracy or truth of the
                  contents, or endorse the products, services or information
                  found on said websites. You access those links and
                  corresponding websites at your own risk.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Law of the Contract (Governing Law) and Jurisdiction.
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  These Terms, all documents incorporated by reference and your
                  relationship with us shall be governed by, construed and
                  enforced in accordance with the laws of the Province of
                  Ontario, Canada, and any Canadian federal laws applicable
                  therein, as such laws are applied to agreements entered into
                  and to be performed entirely within such province.
                  <br></br>
                  <br></br>
                  Aside from any claims or disputes for the failure to pay our
                  fees or other outstanding amounts owing to us which fall
                  entirely within the jurisdiction of the Small Claims Court in
                  Ontario, Canada:
                </span>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  · If any dispute, controversy or claim arising out of or in
                  any way connected to these Terms, between you and us, occurs,
                  whether relating to the application, interpretation,
                  enforcement, performance, breach, implementation or validity
                  of these Terms or otherwise (including any schedules or
                  documents incorporated by reference), you agree to resolve the
                  dispute by arbitration at ADR Chambers using the ADR Chambers
                  Expedited Arbitration Rules
                  <a href="https://adrchambers.com/expedited-arbitration/rules/">
                    <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                      {" "}
                      (https://adrchambers.com/expedited-arbitration/rules/).
                    </span>
                  </a>
                  You agree that the ADR Chambers Expedited Arbitration Rules
                  give you a fair opportunity to present your case and respond
                  to the case of the other side. The arbitration shall be held
                  in Toronto, Ontario (or at the discretion of the arbitrator,
                  electronically or in writing) and shall proceed in accordance
                  with the provisions of the Arbitration Act (Ontario). Judgment
                  upon the award rendered by the arbitrator may be entered in
                  any court having jurisdiction. There will be no appeal from
                  the decision of the arbitrator on questions of fact, law, or
                  mixed fact and law; and
                  <br></br>
                  <br></br>· If, for any reason, the above arbitration provision
                  is not applicable, or not enforceable, for any reasons, you
                  agree to resolve any and all disputes arising from or in any
                  way related to these Terms in a court of competent
                  jurisdiction in Ontario, Canada, including any applicable
                  Canadian Federal Courts therein, as applicable.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Severability
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  If any provision of these Terms are found to be unlawful,
                  void, or for any reason unenforceable, then that provision
                  shall be deemed severable from this agreement and shall not
                  affect the validity and enforceability of any remaining
                  provisions.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    No Interpretation Against Drafter
                  </span>
                </b>
              </p>

              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  If any ambiguity or question of intent arises with respect to
                  any provision of these Terms, the Terms shall be construed as
                  if drafted jointly by the parties and no presumption or burden
                  of proof will arise favouring or disfavouring either party by
                  virtue of authorship of any of the provisions.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Waiver of Class Proceedings and Trial By Jury
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  To the extent permitted by law, you hereby waive your right to
                  participate in any class action lawsuits or arbitrations
                  against us, our contractors, employees, shareholders,
                  successors, assigns and directors. To the extent permitted by
                  law, you further waive any right to a trial by jury, should
                  such a right exist, in relation to any legal dispute connected
                  to or in any way arising out of these Terms.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Incorporation by Reference
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  All policies referred to in these Terms or anywhere on the
                  MyPick.Is Platform are hereby incorporated by reference,
                  including but not limited to our Privacy Policy.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Termination
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Subject to the terms of an applicable Platform Agreement, you
                  can stop using the MyPick.Is Platform at any time. Please
                  contact us to learn more about terminating your account.
                  Notwithstanding your decision to delete your account or nor
                  longer use the MyPick.Is Platform, you (or the appliable
                  organization who entered a Platform Agreement) agree to pay
                  all fees and taxes as set out in these Terms and any
                  applicable Platform Agreement.
                  <br></br>
                  <br></br>
                  We also reserve the right to suspend your account or access to
                  the MyPick.Is Platform at any time, with or without reason or
                  cause, and with or without notice.
                  <br></br>
                  <br></br>
                  The cancellation, suspension or termination of access to the
                  MyPick.Is Platform shall not terminate this agreement. In
                  particular, and without limiting the generality of the
                  foregoing, any provision concerning the limitation of our
                  liability, your indemnification obligations, settling disputes
                  (including the jurisdiction and choice of law) shall remain
                  binding.
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Assignment of this Agreement
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  These Terms shall enure to the benefit of and is binding upon
                  the parties and their respective successors and permitted
                  assigns. You agree that we may assign this agreement to any
                  successor or assignee, whether pursuant to the purchase of the
                  MyPick.Is Platform by a third party, the transfer of control
                  of Innapex Incorporated or otherwise.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Right to Seek Injunction
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Violation of these Terms may cause us irreparable harm and,
                  therefore, you agree that we will be entitled to seek
                  extraordinary relief including, but not limited to, temporary
                  restraining orders, preliminary injunctions and permanent
                  injunctions without the necessity of posting a bond or other
                  security, in addition to and without prejudice to any other
                  rights or remedies that we may have for a breach of these
                  Terms.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    Waiver
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Our failure to enforce any right or provision of these Terms
                  will not be deemed a waiver of such right or provision.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{
                  marginBottom: "12.0pt",
                  textAlign: "justify",
                  textJustify: "inter-ideograph",
                }}
              >
                <b>
                  <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                    New Features
                  </span>
                </b>
              </p>
              <p className="MsoNormal">
                <span style={{ fontFamily: "Helvetica, sans-serif" }}>
                  Any new features that augment or enhance the current the
                  MyPick.Is Platform, including the release of new versions, new
                  products or services, tools and resources, shall be subject to
                  these Terms.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
